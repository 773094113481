import React, { useContext, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAdminAddProduct from '../../hooks/UseAdminAddProduct.js';
import '../../styles/admin/adminAddProduct.css';
import { CategoriesContext } from '../../context/categoriesContext.js';

const AddProduct = () => {
  const [currentProduct, setCurrentProduct] = useState({
    title: '',
    priceARS: 0,
    priceUSD: 0, 
    category: '',
    description: '',
    images: [],
    fileadj: [],
    mercadoLibreUrl: '',
    position: '', // Nuevo campo para la posición
  });
  const { categories } = useContext(CategoriesContext);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [fileUrls, setFileUrls] = useState(['']);
  const { addProduct, isLoading } = useAdminAddProduct();

  const formatPrice = (value) => {
    if (value === '') return '';
    const cleanedValue = value.replace(/\D/g, '');
    const formattedValue = Number(cleanedValue).toLocaleString('es-AR');
    return formattedValue;
  };

  const parsePrice = (value) => {
    return Number(value.replace(/\./g, '').replace(',', '.'));
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'images') {
      const filesArray = Array.from(files);
      setCurrentProduct((prevProduct) => ({
        ...prevProduct,
        images: [...prevProduct.images, ...filesArray],
      }));

      filesArray.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreviews((prevPreviews) => [...prevPreviews, e.target.result]);
        };
        reader.readAsDataURL(file);
      });
    } else if (name === 'priceARS' || name === 'priceUSD') {
      const formattedPrice = formatPrice(value);
      setCurrentProduct((prevProduct) => ({
        ...prevProduct,
        [name]: formattedPrice,
      }));
    } else if (name.startsWith('fileUrl')) {
      const index = parseInt(name.split('-')[1]);
      const newFileUrls = [...fileUrls];
      newFileUrls[index] = value;
      setFileUrls(newFileUrls);
      setCurrentProduct((prevProduct) => ({
        ...prevProduct,
        fileadj: newFileUrls,
      }));
    } else {
      setCurrentProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const addFileUrlInput = () => {
    setFileUrls([...fileUrls, '']);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', currentProduct.title);
    formData.append('priceARS', parsePrice(currentProduct.priceARS));
    formData.append('priceUSD', parsePrice(currentProduct.priceUSD)); 
    formData.append('category', currentProduct.category);
    formData.append('description', currentProduct.description);
    formData.append('mercadoLibreUrl', currentProduct.mercadoLibreUrl);
    formData.append('position', currentProduct.position); // Agregar la posición

    currentProduct.images.forEach((image) => {
      formData.append('images', image);
    });

    currentProduct.fileadj.forEach((fileUrl) => {
      formData.append('fileadj', fileUrl);
    });

    try {
      await addProduct(formData);
      toast.success('Producto agregado exitosamente!');
      resetForm();
    } catch (error) {
      toast.error(`Error al agregar el producto: ${error.message}`);
    }
  };

  const resetForm = () => {
    setCurrentProduct({
      title: '',
      priceARS: 0, 
      priceUSD: 0,
      category: '',
      description: '',
      images: [],
      fileadj: [],
      mercadoLibreUrl: '',
      position: '', // Restablecer la posición
    });
    setImagePreviews([]);
    setFileUrls(['']);
  };

  return (
    <>
      <form className="add-product-form" onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="form-group">
          <label className="form-label" htmlFor="title">Nombre:</label>
          <input
            className="form-input"
            type="text"
            id="title"
            name="title"
            value={currentProduct.title}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="priceARS">Precio (ARS):</label>
          <input
            className="form-input"
            type="text"
            id="priceARS"
            name="priceARS"
            value={currentProduct.priceARS}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="priceUSD">Precio (USD):</label>
          <input
            className="form-input"
            type="text"
            id="priceUSD"
            name="priceUSD"
            value={currentProduct.priceUSD}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <p>*Por más que la categoría sea la que está por defecto, abri el contenedor y selecciónala</p>
          <label className="form-label" htmlFor="category">Categoría:</label>
          <select
            className="form-input"
            id="category"
            name="category"
            value={currentProduct.category}
            onChange={handleInputChange}
            required
          >
            {categories.map((category) => (
              <option key={category._id} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="description">Descripción:</label>
          <textarea
            className="form-input"
            id="description"
            name="description"
            value={currentProduct.description}
            onChange={handleInputChange}
            required
            rows="4"
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="mercadoLibreUrl">URL de Mercado Libre:</label>
          <input
            className="form-input"
            type="url"
            id="mercadoLibreUrl"
            name="mercadoLibreUrl"
            value={currentProduct.mercadoLibreUrl}
            onChange={handleInputChange}
            placeholder="https://articulo.mercadolibre.com.ar/"
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="position">Posición en numero:</label>
          <input
            className="form-input"
            type="Number"
            id="position"
            name="position"
            value={currentProduct.position}
            onChange={handleInputChange}
            placeholder="Posición en la lista"
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="images">Imágenes:</label>
          <input
            className="form-input"
            type="file"
            id="images"
            name="images"
            onChange={handleInputChange}
            accept="image/*"
            multiple
            required
          />
        </div>
        <div className="image-previews">
          {imagePreviews.map((image, index) => (
            <img key={index} src={image} alt={`Previsualización ${index}`} />
          ))}
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="fileUrl-0">URL de archivo:</label>
          {fileUrls.map((fileUrl, index) => (
            <input
              key={index}
              className="form-input"
              type="url"
              id={`fileUrl-${index}`}
              name={`fileUrl-${index}`}
              value={fileUrl}
              onChange={handleInputChange}
              placeholder="https://example.com/archivo.pdf"
            />
          ))}
          <button type="button" onClick={addFileUrlInput} className="add-url-button">
            Agregar otra URL
          </button>
        </div>

        <button className="submit-button" type="submit" disabled={isLoading}>
          {isLoading ? 'Agregando...' : 'Agregar Producto'}
        </button>
      </form>
      <ToastContainer />
    </>
  );
};

export default AddProduct;
