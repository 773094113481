import React, { useState } from 'react';
import '../../styles/DrawerMethodsPay/paymentInfoLinks.css';
import { FaCreditCard, FaPaypal, FaMoneyBillWave, FaUniversity } from 'react-icons/fa';

const PaymentInfoLinks = () => {
    const [hoveredMethod, setHoveredMethod] = useState(null);

    const paymentMethods = [
        { name: 'Tarjetas de crédito', info: 'Aceptamos Visa, Mastercard, American Express y otras.', icon: <FaCreditCard /> },
        { name: 'Tarjetas de débito', info: 'Utiliza tu Visa Débito, Maestro, y más.', icon: <FaUniversity /> },
        { name: 'Mercado Pago', info: 'Paga con el saldo de tu cuenta en Mercado Pago.', icon: <FaMoneyBillWave /> },
        { name: 'PayPal', info: 'Realiza tu pago en dólares a través de PayPal.', icon: <FaPaypal /> },
    ];

    const handleMouseEnter = (index) => {
        setHoveredMethod(index);
    };

    const handleMouseLeave = () => {
        setHoveredMethod(null);
    };

    return (
        <div className="payment-info-cards-container">
            <h4>Métodos de Pago</h4>
            <div className="payment-icons">
                {paymentMethods.map((method, index) => (
                    <div
                        key={index}
                        className="payment-icon"
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                    >
                        {method.icon}
                        {hoveredMethod === index && (
                            <div className="payment-info-card">
                                <h5>{method.name}</h5>
                                <p>{method.info}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PaymentInfoLinks;