import { useState } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../config/config';

const useUpdateCartItem = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateCartQuantity = async (lsCart, productID, newQuantity) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/carts/${lsCart}/product/${productID}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          quantity: newQuantity
        }),
      });

      if (!response.ok) {
        throw new Error('Error updating product quantity in cart');
      }

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, updateCartQuantity };
};

export default useUpdateCartItem;