import { useState } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../config/config.js';

const UseCartCreate = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const cartCreateHook = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/carts/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Error create cart hook');
      }

      const cartCreate = await response.json();

      localStorage.setItem("cart", cartCreate._id)

      return cartCreate;
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, cartCreateHook };
};

export default UseCartCreate;