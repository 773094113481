import { useState, useEffect } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../config/config.js';

const useProducts = (categoryName, filters, currentPage) => {
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);

  const ITEMS_PER_PAGE = 12;

  const fetchProducts = async () => {
    setLoading(true);
    setError(null);

    try {
      const params = new URLSearchParams({
        page: currentPage,
        limit: ITEMS_PER_PAGE,
        ...filters,
      });

      if (categoryName) {
        params.append('category', categoryName);
      }

      const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/products?${params.toString()}`);

      if (!response.ok) {
        throw new Error('Error al cargar los productos');
      }

      const data = await response.json();

      setProducts(data);
      setTotalPages(data.totalPages); 
    } catch (err) {
      setError(err.message || 'Error al cargar los productos');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [categoryName, filters, currentPage]); 

  const goToPage = (pageNumber) => {
    setLoading(true);
    setTotalPages(pageNumber);
  };

  return {
    products,
    loading,
    error,
    totalPages,
    goToPage,
  };
};

export default useProducts;
