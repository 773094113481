import { useState } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../config/config';

const useGetProdInCart = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchProdInCart = async (lsCart, productID) => {
    if (!productID || !lsCart) {
      setError('ID del producto no está definido');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/carts/${lsCart}/add/${productID}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('No se pudo obtener el producto');
      }

      const productData = await response.json();
   
      setLoading(false);
      return productData;
    } catch (error) {
      setError(error.message);
      setLoading(false);
      return null;
    }
  };

  return { loading, error, fetchProdInCart };
};

export default useGetProdInCart;