import React from 'react';
import '../../styles/navbar/category.css';
import { Link } from 'react-router-dom';


const Category = () => {

    return (
        <nav className="navbar">
            <ul className="nav-links-category">
                <li><Link to={'/'}>INICIO</Link></li>
                <li><Link to={'/all'}>TIENDA</Link></li>
                <li><Link to={'/faqs'}>FAQs</Link></li>
                <li><a href="/#contact-section-id">CONTACTO</a></li>
            </ul>
        </nav>
    );
};

export default Category;
