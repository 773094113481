import { useState, useCallback } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../config/config';

const useCurrencyConverter = () => {
  const [conversionRate, setConversionRate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const convertCurrency = useCallback(async (amountARS) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/paypal/converter-currency`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ amountARS }),
      });

      if (!response.ok) {
        throw new Error('No se pudo obtener la cotización del dólar.');
      }

      const data = await response.json();

      setConversionRate(data.amountUSD);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  return { conversionRate, loading, error, convertCurrency };
};

export default useCurrencyConverter;
