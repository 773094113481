import React from "react";

import TransactionList from "./TransactionList.jsx";
import useTransaction from "../../hooks/UseTransactions.js";

const Ticket = () => {
  const { transactions, loading } = useTransaction();


  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div>
      {transactions ? (
        <TransactionList
          transactions={transactions}
        />
      ) : (
        <div>No hay transacciones disponibles.</div>
      )}
    </div>
  );
};

export default Ticket;