import React, { useEffect, useState } from "react";
import ItemDetail from "./itemDetail.js";
import '../styles/itemDetailContainer.css'
import { useParams } from 'react-router-dom'
import useProductById from '../hooks/useProductsById.js'
import useProducts from "../hooks/useProducts.js";
import MiddlePart from './body/middlePart.jsx'
import Item from "./Item.jsx";

export const ItemDetailContainer = () => {
    const { id } = useParams();
    const { product, loading, error } = useProductById(id);
    const [recommendedProducts, setRecommendedProducts] = useState([]);
    const { products, loading: recLoading, error: recError } = useProducts(product?.category);

    useEffect(() => {
        if (products && products.payload) {
            const filteredProducts = products.payload.filter(p => 
                p.category === product?.category && p._id !== product?._id
            ).slice(0, 4);
            setRecommendedProducts(filteredProducts);
        }

    }, [products, id]);

    return (
        <>

            <div className="item-detail-container">
                {loading && <p>Cargando...</p>}
                {error && <p>Ocurrió un error: {error.message}</p>}
                {product && <ItemDetail producto={product} />}
            </div>
            {recommendedProducts.length > 1 && (
                <div className="recommended-products-container">
                    <h2>Recomendados</h2>

                        <div className="recommended-products">
                            {recommendedProducts.map((product) => (
                                <Item key={product._id} product={product} />
                            ))}
                        </div>
                        

                </div>
            )}
            
            <MiddlePart />
        </>
    )
}

export default ItemDetailContainer;