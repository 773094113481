import React from 'react';
import '../../styles/body/imgBody.css';
import { Link } from 'react-router-dom';

const ImgBody = () => {

  return (
    <div>

      <div className='images-main'>
        <div className='img-container'>
          <img src={`mainback2.png`} alt='header1' />
        </div>

        <div className='false-img'>
          <div className='content-false'>
              <div className='div-img'>
                <img className='img-minz' src={`logo-minz.png`} alt='logo-minz'></img>
              </div>

              <div className='intern-false-img'>

                <div className='content-main'>
                  <h1>¡Todo lo que te gusta en un mismo lugar!</h1>
                  <h2>Descubrí todos nuestros productos digitales en la tienda online. Sin tiempos de envío, sin precios exagerados.</h2>
                  <h2>¡Podés comprar desde cualquier parte del mundo y con la moneda que quieras!</h2>
                  <Link to="/all">
                    <button className='btn-main'>Ver tienda</button>
                  </Link>
                </div>

              </div>
          </div>
   
        </div>

      </div>

    </div>
  );
};

export default ImgBody;