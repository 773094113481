import React, { useEffect, useState } from "react";
import '../../styles/admin/productList.css';

const ProductList = ({ products, onSelectProduct, onDeleteProduct }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProducts, setFilteredProducts] = useState(products || []);

    useEffect(() => {
        if (products) {
            // Filtrar productos por término de búsqueda
            const searchFilteredProducts = products.filter(product =>
                product.title.toLowerCase().includes(searchTerm.toLowerCase())
            );

            // Ordenar productos por posición (ascendente) y por aquellos sin posición al final
            const sortedProducts = searchFilteredProducts.sort((a, b) => {
                if (a.position == null && b.position == null) return 0; // Si ambos no tienen posición, mantenlos en el orden original
                if (a.position == null) return 1; // Si solo 'a' no tiene posición, ponlo al final
                if (b.position == null) return -1; // Si solo 'b' no tiene posición, ponlo al final
                return a.position - b.position; // Ordenar por posición ascendente
            });

            setFilteredProducts(sortedProducts);
        }
    }, [searchTerm, products]);

    return (
        <div className="product-list">
            <h3 className="product-list-title">Todos los productos</h3>

            <input
                type="text"
                placeholder="Buscar producto..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />

            <ul className="product-list-items">
                {Array.isArray(filteredProducts) && filteredProducts.length > 0 ? (
                    filteredProducts.map((product) => (
                        <li key={product.id} className="product-list-item">
                            <div className="child-container">
                                <span className="product-title">{product.title} - ${product.priceARS}</span>
                            </div>
                            
                            <span className="product-position">Pos: {product.position !== null ? product.position : 'No asignada'}</span>

                            <div className="bts-admin-list">
                                <div><button className="modify-button" onClick={() => onSelectProduct(product)}>Modificar</button></div>
                                <div><button className="delete-button" onClick={() => onDeleteProduct(product._id)}>Eliminar</button></div>    
                            </div>
                        </li>
                    ))
                ) : (
                    <p className="no-products">No hay productos disponibles.</p>
                )}
            </ul>
        </div>
    );
};

export default ProductList;
