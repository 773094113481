import React, { createContext, useContext, useState, useEffect, useMemo, useRef } from 'react';
import UseCartCreate from '../hooks/UseCartCreate';
import useCartById from '../hooks/UseCartById';
import useGetProdInCart from '../hooks/UseAddProductToCart';
import useClearCart from '../hooks/UseDeleteProdInCart';
import useUpdateCartItem from '../hooks/UseUpdateQuantityCart';

export const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const { cartCreateHook } = UseCartCreate();
  const { fetchCartHook } = useCartById();
  const { fetchProdInCart } = useGetProdInCart();
  const { clearCart } = useClearCart();
  const { updateCartQuantity } = useUpdateCartItem();
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [change, setChange] = useState(false);

  useEffect(() => {
    const fetchCart = async () => {
      setLoading(true);
      try {
        const lsCart = localStorage.getItem("cart");
        if (!lsCart) {
          await cartCreateHook();
        }
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchCart();
  }, []);

  useEffect(() => {
    const getCart = async () => {
      setLoading(true);
      try {
        const lsCart = localStorage.getItem("cart");
        if (lsCart) {
          const getCartFetch = await fetchCartHook(lsCart);

          if (!getCartFetch) {
            throw new Error('No se pudo obtener el carrito');
          }

          setCart(getCartFetch);
          setLoading(false);
        } else {
          console.log('No se pudo crear un carrito.');
        }

      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    getCart();
  }, [change]);

  const addToCart = async (productID) => {
    setLoading(true);
    try {
      const lsCart = localStorage.getItem("cart");
      if (lsCart) {
        const productAdd = await fetchProdInCart(lsCart, productID);

        if (!productAdd) {
          throw new Error('No se pudo agregar el producto');
        }

        setChange(prev => !prev);
        setLoading(false);
      }


    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const partialCost = (cart) => {
    let totalCost = 0;
    cart.forEach(item => {
      if (item.productID && typeof item.productID.priceARS === 'number') {
        const itemCost = item.productID.priceARS * item.cant;
        totalCost += itemCost;
      }
    });

    return totalCost;
  };

  const partialCostUSD = (cart) => {
    let totalCost = 0;
    cart.forEach(item => {
      if (item.productID && typeof item.productID.priceUSD === 'number') {
        const itemCost = item.productID.priceUSD * item.cant;
        totalCost += itemCost;
      }
    });

    return totalCost;
  };

  const deleteProductInCart = async (productID) => {
    setLoading(true);
    try {
      const lsCart = localStorage.getItem("cart");
      if (lsCart) {
        await clearCart(lsCart, productID);

        setChange(prev => !prev);
        setLoading(false);
      } else {
        setLoading(true);
      }

    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const updateCartItem = async (productID, updatedQuantity) => {
    setLoading(true);
    try {
      const lsCart = localStorage.getItem("cart");
      if (lsCart) {
        await updateCartQuantity(lsCart, productID, updatedQuantity);

        setChange(prev => !prev);
        setLoading(false);
      } else {
        setLoading(true);
      }

    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const cartQuantity = useMemo(() => {
    return cart.length;
  }, [cart]);

  const cartValue = {
    cart,
    addToCart,
    deleteProductInCart,
    partialCostUSD,
    partialCost,
    updateCartItem,
    cartQuantity,
    loading,
    error
  };

  return (
    <CartContext.Provider value={cartValue}>
      {children}
    </CartContext.Provider>
  );
};