import React from 'react';
import { useFeaturedProducts } from '../context/featuredProducts.js';
import '../styles/body/featuredComponent.css';
import Item from './Item.jsx';

const FeaturedProductsButtonBase = () => {
  const { featuredProducts } = useFeaturedProducts();

  return (
    <div className="featured-products-container">
      
      {featuredProducts.map((product) => (
        <div className="product-link">
 
            <Item product={product}/>
       
        </div>
      ))}
    </div>
  );
};

export default FeaturedProductsButtonBase;
