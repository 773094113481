import { useState } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../config/config';

const useClearCart = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const clearCart = async (lsCart, productID) => {
    setLoading(true);
    setError(null);

    try {
        const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/carts/${lsCart}/product/${productID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Error borrando producto.');
      }

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, clearCart };
};

export default useClearCart;