import React, { createContext, useContext, useState, useEffect } from 'react';
import useProfile from '../hooks/useProfile';

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const { profile, loading } = useProfile();

    useEffect(() => {
      if (!loading) {

          if (profile && profile.status === 'success') {
              console.log('Autenticado con éxito:');
              setIsLoggedIn(true);
          } else {
              setIsLoggedIn(false);
              window.location.replace("/")
          }
      }
  }, [profile]);


    const adminLogin = () => {
        setIsLoggedIn(true);
    };

    const adminLogout = () => {
        setIsLoggedIn(false);
    };

    const authValue = {
        isLoggedIn,
        adminLogin,
        adminLogout,
    };

    return (
        <AuthContext.Provider value={authValue}>
            {children}
        </AuthContext.Provider>
    );
};