import React from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import '../styles/detailBreadCrumb.css'
import { Link } from 'react-router-dom'
import 'primeicons/primeicons.css';

export default function DetailBreadCrumb({ categoryName, title }) {
    const items = [
        {
            label: 'Inicio',
            template: (item) => <Link to="/">{item.label}</Link>
        }
    ];

    if (categoryName) {

        items.push({
            label: 'Todos los productos',
            template: (item) => <Link to={`/all`}>{item.label}</Link>
        });

        items.push({
            label: categoryName,
            template: (item) => <Link to={`/category/${categoryName}`}>{item.label}</Link>
        });
    }

    if (title) {
        items.push({ label: title });
    }

    const home = { icon: 'pi pi-home', url: '/all' }; 

    return (
        <div className="custom-breadcrumb">
            <BreadCrumb model={items} home={home} />
        </div>
    );
}