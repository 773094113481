import React from 'react';
import '../../styles/body/featuredProducts.css'; 
import FeaturedProductsButtonBase from '../feature.jsx';
import { Link } from 'react-router-dom';


const FeaturedProducts = () => {
 
  return (
    <div >

      <div className='titles-featured-container'>
        <h3 className='titleh2'>PRODUCTOS DESTACADOS</h3>
        <Link to={"/all"}><p>Ver todos</p></Link>
      </div>

      <div className='container-featured'>
        
        <FeaturedProductsButtonBase/>

      </div>
    </div>
  );
};

export default FeaturedProducts;

