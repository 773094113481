import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ItemsListContainer from './components/itemsListContainer.js';
import ItemDetailContainer from './components/itemDetailContainer.js'
import Navbar from './components/navbar/navbar.jsx';
import Cart from './components/Cart.js';
import { CartProvider } from './context/cartContext.js';
import Checkout from './components/checkout/Checkout.jsx';
import PanelAdmin from './components/admin/PanelAdmin.jsx'
import LoginModal from './components/loginModal.js';
import { AuthProvider } from './context/authAdmin.js';
import CancelPage from './components/payPalCancel.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import Body from './components/body/Body.jsx';
import WhatsAppButton from './components/icons/WspIcon.jsx';
import Footer from './components/footer/Footer.jsx';
import { CategoriesProvider } from './context/categoriesContext.js';
import { FeaturedProductsProvider } from './context/featuredProducts.js';
import './App.css'
import { PrimeReactProvider } from 'primereact/api';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import FAQs from './components/FAQs/FAQs.jsx';

function App() {
  
  return (
    <PrimeReactProvider>
    <BrowserRouter>
   
    <CartProvider>
    <AuthProvider>
    <CategoriesProvider>
    <FeaturedProductsProvider>

    <section className='app'>
    <Navbar />
           
           <Routes>
            <Route path="/" element={<Body />} />
            <Route path="/all" element={<ItemsListContainer />} />
            <Route
              path="/category/:categoryName"
              element={<ItemsListContainer />}
            />
            <Route path="/products/:id" element={<ItemDetailContainer />} />
            <Route path="/cart" element={<Cart />}></Route>
            <Route path='/checkout' element={<Checkout />}></Route>
            <Route path='/login' element={<LoginModal />}> </Route>
            <Route path='/admin' element={<PanelAdmin />}></Route>
            <Route path='/cancel-payment' element={<CancelPage />}></Route>
            <Route path='/faqs' element={<FAQs />}></Route>
            <Route path='/login' element={<LoginModal />}></Route>
           </Routes>
           
           <Footer />
    </section>
  
        
        <WhatsAppButton />

      </FeaturedProductsProvider>
      </CategoriesProvider>
      </AuthProvider>
      </CartProvider>
   
    </BrowserRouter>
    </PrimeReactProvider>
  );
}

export default App;
