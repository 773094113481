import React from 'react';
import '../../styles/body/contactSection.css';
import 'react-toastify/dist/ReactToastify.css';

const Contacto = () => {
  return (
    <div>

      <div className='content-contact'>

        <div className='content-img'>
          <img src="piece1.png" alt="piece of alfil" />
        </div>

        <div className='content-form'>
          <h4 id='contact-section-id' className='h4contact'>Escaneá el QR para iniciar un chat con nosotros</h4>
          <img src="contact-img.png" alt="wsp-contact" />

          <p className='wsp-link'><em>
              <a href='https://wa.me/message/LCVK2PT67LHNG1' target='_blank' rel='noopener noreferrer' className='whatsapp-link'>O haz click aquí</a>.
            </em>        

          </p>

        </div>

      </div>

    </div>
  );
};

export default Contacto;