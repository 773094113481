import React, { useContext, useEffect, useState } from 'react';
import useProducts from '../hooks/useProducts'; 
import ItemList from './itemsList'; 
import { useParams } from 'react-router-dom';
import '../styles/itemsListContainer.css';
import { CategoriesContext } from '../context/categoriesContext';
import DetailBreadCrumb from './BreadCrumb';
import { Button } from 'primereact/button'; 
import 'primeicons/primeicons.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import MiddlePart from './body/middlePart';
import { FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa';
import { GrSort } from "react-icons/gr";
import '../styles/SortOptions.css';

const ItemsListContainer = () => {
  const { categoryName } = useParams(); 
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({}); 
  const { products, loading, error, totalPages, goToPage } = useProducts(categoryName, filters, currentPage);
  const { categories } = useContext(CategoriesContext);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [sortOrder, setSortOrder] = useState('default');

  const handleCategoryChange = (category) => {
    setSelectedCategories(prevSelected => 
      prevSelected.includes(category)
        ? prevSelected.filter(c => c !== category)
        : [...prevSelected, category]
    );
  };

  useEffect(() => {
    const newFilters = {
      searchTerm,
      categories: selectedCategories.length > 0 ? selectedCategories : [], 
      sortOrder: sortOrder !== 'default' ? sortOrder : undefined,
    };

    setFilters(newFilters);
  }, [searchTerm, selectedCategories, sortOrder]);

  const handleSortChange = (value) => {
    setSortOrder(value);
  };

  const toggleAccordion = (accordionName) => {
    setOpenAccordion(openAccordion === accordionName ? null : accordionName);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    goToPage(newPage);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleOptionClick = (value) => {
    handleSortChange(value);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className='itemListContainer'>
      <div className='filterContainer'>
        <DetailBreadCrumb categoryName={categoryName} title={''} />
      </div>

      <div className="greeting">
        <div className='categorias'>
          <div className='cont-sticky'>
            <div className='filter-h3'>
              <h3>Filtrar por</h3>
              <div className='container-accordion-filter'>
                {/* Categorías */}
                <div className="accordion">
                  <button className="accordion-button-filters" onClick={() => toggleAccordion('categories')} aria-expanded={openAccordion === 'categories'}>
                    <div className="accordion-button-content">
                      <h4>Categorías</h4>
                      <i className={`fas fa-chevron-up ${openAccordion === 'categories' ? 'rotate' : ''}`}></i>
                    </div>
                  </button>
                  <div className={`accordion-content ${openAccordion === 'categories' ? 'open' : ''}`}>
                    {categories.map(category => (
                      <div key={category._id}>
                        <input 
                          type="checkbox" 
                          id={category._id} 
                          value={category.name}
                          checked={selectedCategories.includes(category.name)}
                          onChange={() => handleCategoryChange(category.name)}
                        />
                        <label htmlFor={category._id}>{category.name}</label>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Buscar */}
                <div className="accordion">
                  <button className="accordion-button-filters" onClick={() => toggleAccordion('name')} aria-expanded={openAccordion === 'name'}>
                    <div className="accordion-button-content">
                      <h4>Buscar</h4>
                      <i className={`fas fa-chevron-up ${openAccordion === 'name' ? 'rotate' : ''}`}></i>
                    </div>
                  </button>
                  <div className={`accordion-content ${openAccordion === 'name' ? 'open' : ''}`}>
                    <div className='form-search'>
                      <form>
                        <label className='label-search'>Buscar productos:</label>
                        <input 
                          className='input-name'
                          type="text" 
                          name="name" 
                          value={searchTerm} 
                          onChange={(e) => setSearchTerm(e.target.value)} 
                          placeholder="Escribí al menos 3 caracteres..." 
                          onKeyDown={handleKeyDown}
                        />
                      </form>
                    </div>
                  </div>
                </div>

                {/* Ordenar */}
                <div className="accordion">
                  <button className="accordion-button-filters" onClick={() => toggleAccordion('sort')} aria-expanded={openAccordion === 'sort'}>
                    <div className="accordion-button-content button-content-sort" data-tooltip="Ordenar">
                      <GrSort />
                      <i className={`${openAccordion === 'sort' ? 'rotate' : ''}`}></i>
                    </div>
                  </button>
                  <div className={`accordion-content ${openAccordion === 'sort' ? 'open-sort' : ''}`}>
                    <div className="sort-options-container">
                      <div className="sort-options-menu">
                        <div className="sort-option" onClick={() => handleOptionClick('default')}>
                          <FaSortAmountDown className="sort-icon" />
                          Predeterminado
                        </div>
                        <div className="sort-option" onClick={() => handleOptionClick('price')}>
                          <FaSortAmountDown className="sort-icon" />
                          Precio (Menor a Mayor)
                        </div>
                        <div className="sort-option" onClick={() => handleOptionClick('-price')}>
                          <FaSortAmountUp className="sort-icon" />
                          Precio (Mayor a Menor)
                        </div>
                        <div className="sort-option" onClick={() => handleOptionClick('title')}>
                          <FaSortAmountDown className="sort-icon" />
                          Título (A-Z)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>

        <div className='container-items'>
          {/* Cargando */}
          {loading && (
            <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
          )}
          {error && <p>Error: {error}</p>}

          {/* Lista de productos */}
          <ItemList productos={products?.payload || []} />

          {/* Paginación */}
          <div className="pagination">
            {currentPage > 1 && (
              <Button onClick={() => { handlePageChange(currentPage - 1); scrollToTop(); }} icon="pi pi-angle-left" className="p-button-text" />
            )}
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                key={index + 1}
                onClick={() => { handlePageChange(index + 1); scrollToTop(); }}
                label={index + 1}
                className={currentPage === index + 1 ? 'p-button-outlined' : 'p-button-text'}
              />
            ))}
            {currentPage < totalPages && (
              <Button onClick={() => { handlePageChange(currentPage + 1); scrollToTop(); }} icon="pi pi-angle-right" className="p-button-text" />
            )}
          </div>

          {/* Sin productos */}
          {products?.payload?.length === 0 && !loading && (
            <div className='no-products-message'>
              <h3>No se encontraron productos</h3>
            </div>
          )}
        </div>
      </div>
      <MiddlePart />
    </div>
  );
};

export default ItemsListContainer;
