import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from '../context/cartContext';
import { BsTrash3 } from 'react-icons/bs';
import { GrClear } from "react-icons/gr";
import { ProgressSpinner } from 'primereact/progressspinner';
import useDeleteCart from '../hooks/useDeleteCart';
import '../styles/cart/card.css';

const Cart = ({ onClose }) => {
  const { cart, partialCost, partialCostUSD, deleteProductInCart, loading: loadingCart, error: errorCart } = useContext(CartContext);
  const { deleteCart, loading: loadingDelete, error: errorDelete } = useDeleteCart();

  const handleDeleteProduct = (productID) => {
    deleteProductInCart(productID);
  };

  const handleCheckout = () => {
    onClose();
  };

  const handleClearCart = () => {
    const idCartStorage = localStorage.getItem('cart'); 
  
    if (idCartStorage) {
      deleteCart(idCartStorage);  
    } else {
      console.error('No se encontró el ID del carrito en el localStorage');
    }
  };

  if (errorCart || errorDelete) {
    return <div>Error: {errorCart || errorDelete}</div>;
  }

  if (cart.length === 0) {
    return (
      <div className="CartPage">
        <h4 className="cart-title-no">No hay items en el carrito</h4>
        <Link to="/all" className="Option">Ver productos</Link>
      </div>
    );
  }

  return (
    <section className="carrito">
      
      <div className="CartPage">
        <ul>
          {cart.map((product) => (
            <li className="CartItem" key={product._id}>
              <div className="lis">
                <div className="divContent">
                  <h3>{product.productID ? product.productID.title : 'Producto sin título'}</h3>
                  <div className="price-text-content">
                    <p className="p-text">$ {product.productID?.priceARS || 'N/A'} ARS</p>
                    <p className="p-text">$ {product.productID?.priceUSD || 'N/A'} USD</p>
                  </div>
                </div>

                {loadingCart || loadingDelete ? (
                  <div className="spinner-container">
                    <ProgressSpinner className="spiner" />
                  </div>
                ) : (
                  <button onClick={() => handleDeleteProduct(product.productID?._id)} className="buttonClear">
                    <BsTrash3 />
                  </button>
                )}

                <div className="product-image">
                  {product.productID?.images?.[0] && (
                    <img
                      src={product.productID.images[0]}
                      alt={`Imagen de ${product.productID.title}`}
                    />
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="precio">
        
        <button onClick={handleClearCart} className="buttonClearAll">
          <GrClear className="delete-cart-icon" /> Limpiar Carrito
        </button>

        <div className="container-total-price">
          <h3 className="first-child-total-price">Total:</h3>
          <h3>${partialCost(cart)} ARS</h3>
          <h3>${partialCostUSD(cart)} USD</h3>
        </div>

        <Link to="/checkout" className="checkout">
          <button onClick={handleCheckout}>COMPRAR</button>
        </Link>

      </div>
    </section>
  );
};

export default Cart;
