import { useState } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../config/config.js';

function useAdminAddProduct() {
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState(null);

    const addProduct = async (formData) => {
        setIsLoading(true);

        try {
            const res = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/products`, {
                method: 'POST',
                body: formData,
            });

            if (!res.ok) {
                const errorData = await res.json();
                throw new Error(errorData.message || 'Error al agregar el producto');
            }

            const data = await res.json();
            setResponse(data);
            return data;
        } catch (err) {
            throw err;
        } finally {
            setIsLoading(false);
        }
    };

    return {
        addProduct,
        isLoading,
        response,
    };
}

export default useAdminAddProduct;
