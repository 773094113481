import React from 'react';
import '../../styles/body/middlePart.css'; // Importa el archivo CSS con los estilos
import { IoIosCard } from "react-icons/io";
import { MdCurrencyExchange } from "react-icons/md";
import { IoWallet } from "react-icons/io5";

const MiddlePart = () => {
    return (
      <div className="middle-part-container">
      <div className="payment-methods">
        <div className="childContainer">
          <div className="icon">
            <IoIosCard />
          </div>
          <div className="internChild">
            <h4 className="title-child">¡ACEPTAMOS TODAS LAS TARJETAS!</h4>
            <p className='p-first'>¡Visa, American Express, Cabal, Mastercard y más!</p>
          </div>
        </div>
      
        <div className="childContainer">
          <div className="icon">
            <IoWallet />
          </div>
          <div className="internChild">
            <h4 className="title-child">MEDIOS DE PAGO</h4>
            <div className="internChild intern-methods-pay">
              <p>Tarjetas</p>
              <p>Mercado Pago</p>
              <p>PayPal</p>
              <p>Transferencia (por WhatsApp)</p>
              <p>Criptomonedas (por WhatsApp)</p>
            </div>
          </div>
        </div>
      
        <div className="childContainer">
          <div className="icon">
            <MdCurrencyExchange />
          </div>
          <div className="internChild">
            <h4 className="title-child">¡ACEPTAMOS TODAS LAS MONEDAS!</h4>
            <div className="accepted-currencies">
              <p>Mediante PayPal podés pagar con la divisa de tu país sin importar dónde estés.</p>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  };
  
  export default MiddlePart;