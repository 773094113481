import React, { useContext, useState } from 'react';
import '../../styles/admin/category.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CategoriesContext } from '../../context/categoriesContext';
import { REACT_APP_HOST_HOOKS_DEV } from '../../config/config.js';

const AddCategory = () => {
    const [categoryName, setCategoryName] = useState('');
    const { categories, setCategories } = useContext(CategoriesContext);

    const handleInputChange = (e) => {
        setCategoryName(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/categories`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: categoryName }),
            });

            if (!response.ok) {
                toast.error('Error al agregar categoria')
            } else {
                toast.success('Categoria agregada.')
                setCategoryName('');
                window.location.replace('/admin');
            }

        } catch (error) {
            toast.error('Error al agregar categoria')
        }

    };

    
    const handleDelete = async (categoryId) => {
        try {
            const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/categories/${categoryId}`, {
                method: 'DELETE',
            });

            if (response.status !== 200) {
                toast.error('Error al conectar con el servidor.');
            }else{
                const updatedCategories = categories.filter(cat => cat._id !== categoryId);

                setCategories(updatedCategories);

                toast.success(`Categoría eliminada correctamente.`);
            }

        } catch (error) {
            toast.error('Error al eliminar categoria')
        }
    };

    return (
        <>
            <div className="categoriesAddContainer">
                <h2 className='categoryAddTitle'>Agregar Categoría</h2>
                <em>*Recordar: Las categorias se van a ver tal cual las escribas, o sea escribi con mayuscula la primera letra y sin faltas de ortografia :)</em>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        value={categoryName}
                        onChange={handleInputChange}
                        placeholder="Nombre de la categoría"
                        className="categoryInput"
                        required
                    />
                    <button type="submit" className="submitButton">
                        Agregar
                    </button>
                </form>
  
            </div>

            <div className="deleteCategoryContainer">
                <h2>Eliminar Categoría</h2>
                <div className="categoryList">
                    {categories.map(category => (
                        <div key={category._id} className="categoryItem">
                            <p>{category.name}</p>
                            <button onClick={() => handleDelete(category._id)}>Eliminar</button>
                        </div>
                    ))}
            </div>
        </div>
        <ToastContainer />
        </>
        
        
    );
};

export default AddCategory;