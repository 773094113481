import React, { useEffect, useState } from 'react';
import '../../styles/body/body.css'
import ImgBody from './imgBody.jsx';
import MiddlePart from './middlePart.jsx'
import FeaturedProducts from './featuredProducts.jsx';
import Contacto from './sectionContact.jsx';
import { useLocation } from 'react-router-dom';
import SuccessPage from '../SuccessPage.jsx';
import { REACT_APP_HOST_HOOKS_DEV } from '../../config/config.js';
import AboutUs from './AboutUs.jsx';

const Body = () => {
  const [paymentId, setPaymentId] = useState('');
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('payment_id');
    const token = searchParams.get('token');

    if(token){
      setPaymentId(token);
    }else if(id){
      setPaymentId(id);
    }

    const fetchData = async () => {
      try {

        if(token){
          const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/paypal/capture-order?token=${paymentId}`,{
            method: 'POST',
          })      
          await response.json()

        }else if (id){
          const responseMP = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/mercado-pago/capture-order?paymentId=${paymentId}`,{
            method: 'POST',
          })      
          await responseMP.json();

        }
      
      } catch (error) {
        console.error('Error al obtener el carrito:', error);
      }
    };

    fetchData();
    

  }, [location.search, paymentId]);

  return (
    <div >
      {(paymentId) ? (
        <SuccessPage payment_id={paymentId} />
      ) : (
        <div className="bodyContainer">
          <div className='partUp'>
            <ImgBody />
          </div>

          <div className='partMiddle'> 
            <FeaturedProducts />
          </div>

          <div className='partDown'>
            <Contacto />
            <AboutUs />
            <MiddlePart />
          </div>
        </div>
      )}
    </div>
  );
};

export default Body;