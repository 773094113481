import { useState } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../config/config.js';

const useCartById = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchCartHook = async (cartId) => {
    if (!cartId) {
      setError('Cart ID no está definido');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/carts/${cartId}`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('No se pudo obtener el carrito');
      }

      const cartData = await response.json();
      setLoading(false);
      return cartData.carrito;
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  return { loading, error, fetchCartHook };
};

export default useCartById;