import React from 'react';
import { Link } from 'react-router-dom';

const CancelPage = () => {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow">
            <div className="card-body text-center">
              <h2 className="text-danger">¡Pago Cancelado!</h2>
              <p className="lead">El pago ha sido cancelado.</p>
              <p>Por favor, intenta realizar el pago nuevamente más tarde.</p>
              <Link to="/" className="btn btn-primary">Volver al Pago</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelPage;