// src/components/PanelAdmin.jsx
import React, { useState } from "react";
import useLogout from "../../hooks/useLogout";
import useDeleteProduct from "../../hooks/useDeleteProdAdmin.js";
import AddProduct from "./AddProduct";
import ProductList from "./ProductList";
import ModifyProduct from "./ModifyProduct";
import '../../styles/admin/panelAdmin.css';
import { toast } from "react-toastify";
import AddCategory from "./AddCategory.jsx";
import FeaturedProductsSelector from "./productDestacados.jsx";
import useProductsAdmin from "../../hooks/useProductsAdmin.js";
import TransactionList from "./ticket.jsx";
import { REACT_APP_HOST_HOOKS_DEV } from "../../config/config.js";

const PanelAdmin = () => {
    const { products, refetchProducts } = useProductsAdmin();
    const { logout } = useLogout();
    const { deleteProduct } = useDeleteProduct();
    const [currentSection, setCurrentSection] = useState('products');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [featuredProducts, setFeaturedProducts] = useState([]);

    const handleSectionChange = (section) => {
        setCurrentSection(section);
        setSelectedProduct(null);
    };

    const handleLogout = () => {
        logout();
    };

    const handleProductSelect = (product) => {
        setSelectedProduct(product);
        setCurrentSection('modifyProduct');
    };

    const handleProductSave = (updatedProduct) => {
        refetchProducts();
        setCurrentSection('products');
        setSelectedProduct(null);
    };

    const handleProductCancel = () => {
        setCurrentSection('products');
        setSelectedProduct(null);
    };

    const handleReorderProduct = async (id, direction) => {
        // Encuentra el índice del producto y actualiza la posición
        const index = products.findIndex(product => product.id === id);
        if (index === -1 || index + direction < 0 || index + direction >= products.length) return;

        const newProducts = [...products];
        const [movedItem] = newProducts.splice(index, 1);
        newProducts.splice(index + direction, 0, movedItem);

        // Actualiza la posición en la base de datos
        await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/products/${id}/reorder`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id, direction }),
        });

        // Refetch productos
        refetchProducts();
    };

    const handleProductDelete = async (productId) => {
        try {
            await deleteProduct(productId);
            refetchProducts();
            toast.success('Producto eliminado exitosamente!');
        } catch (error) {
            toast.error(`Error al eliminar un producto: ${error.message}`);
        }
    };

    const handleFeaturedProductsChange = (selectedProducts) => {
        setFeaturedProducts(selectedProducts);
    };

    return (
        <div className="container">
            <div className="sidebar">
                <h3>Panel de administración</h3>
                <ul>
                    <li>
                        <button onClick={() => handleSectionChange('products')}>Mostrar productos</button>
                    </li>
                    <li>
                        <button onClick={() => handleSectionChange('orders')}>Mostrar pedidos</button>
                    </li>
                    <li>
                        <button onClick={() => handleSectionChange('addProduct')}>Agregar producto</button>
                    </li>
                    <li>
                        <button onClick={() => handleSectionChange('addCategory')}>Agregar categoría</button>
                    </li>
                    <li>
                        <button onClick={() => handleSectionChange('productDestacado')}>Productos Destacados</button>
                    </li>
                    <li>
                        <button onClick={handleLogout}>Salir de la cuenta</button>
                    </li>
                </ul>
            </div>

            <div className="main-content">
                {currentSection === 'products' && (
                    <ProductList
                        products={products}
                        onSelectProduct={handleProductSelect}
                        onDeleteProduct={handleProductDelete}
                        onReorderProduct={handleReorderProduct}
                    />
                )}

                {currentSection === 'orders' && (
                    <div>
                        <h3>Pedidos</h3>
                        <TransactionList />
                    </div>
                )}

                {currentSection === 'addProduct' && (
                    <div>
                        <h3>Agregar producto</h3>
                        <AddProduct />
                    </div>
                )}

                {currentSection === 'modifyProduct' && selectedProduct && (
                    <ModifyProduct
                        product={selectedProduct}
                        onSave={handleProductSave}
                        onCancel={handleProductCancel}
                    />
                )}

                {currentSection === 'addCategory' && (
                    <div>
                        <AddCategory />
                    </div>
                )}

                {currentSection === 'productDestacado' && (
                    <div>
                        <FeaturedProductsSelector 
                            products={products} 
                            onSelectionChange={handleFeaturedProductsChange} 
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default PanelAdmin;
