import React, { useContext, useRef, useEffect } from 'react';
import '../../styles/navbar/cartDrawer.css';
import { CartContext } from '../../context/cartContext';
import Cart from '../Cart';
import { ProgressSpinner } from 'primereact/progressspinner';

const CartDrawer = ({ isOpen, onClose, cartIconRef }) => {
    const { loading, error } = useContext(CartContext);
    const cartDrawerRef = useRef(null);
    const ignoreClose = useRef(false);

    const handleClickOutside = (event) => {
        if (
            isOpen &&
            cartDrawerRef.current &&
            !cartDrawerRef.current.contains(event.target) &&
            (!cartIconRef || !cartIconRef.current.contains(event.target)) &&
            !ignoreClose.current 
        ) {
            onClose(); 
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen, onClose]);

    const handleInternalAction = () => {
        ignoreClose.current = true;
        setTimeout(() => {
            ignoreClose.current = false;
        }, 100); 
    };

    return (
        <>
            {/* Capa de superposición */}
            {isOpen && <div className="overlay" onClick={onClose}></div>}

            <div
                className={`cart-drawer ${isOpen ? 'open' : ''}`}
                ref={cartDrawerRef} 
            >
                <button className="close-btn" onClick={onClose}>×</button>
                <h3>Mi carrito</h3>
                {loading ? (
                            <div className='spinner-container'>
                            <ProgressSpinner className='spiner'/>
                          </div>
                ) : error ? (
                    <p>Error: {error}</p>
                ) : (
                    <div className='container-cart' onClick={handleInternalAction}> 
                        <Cart onClose={onClose}/>
                    </div>
                )}
            </div>
        </>
    );
};

export default CartDrawer;