import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/navbar/navbarDrawer.css';

const NavBarDrawer = ({ isOpen, onClose }) => {
  return (
    <div className={`drawer ${isOpen ? 'open' : ''}`}>
      <nav className="drawer-nav">
        <ul className="drawer-nav-links">
          <li><Link to="/" onClick={onClose}>INICIO</Link></li>
          <li><Link to="/all" onClick={onClose}>TIENDA</Link></li>
          <li><Link to="/faqs" onClick={onClose}>FAQs</Link></li>
          <li><a href="/#contact-section-id" onClick={onClose}>CONTACTO</a></li> 
        </ul>
      </nav>
    </div>
  );
};

export default NavBarDrawer;