import React from 'react';
import '../../styles/body/AboutUs.css';

export const AboutUs = () => {
    return (
        <section className="about-us">
          <h3 className="about-title">¡Compra 100% protegida!</h3>
          <p className="about-description">
          Somos MercadoLíder en la plataforma MercadoLibre. Tu compra está 100% segura con nosotros, recibí lo que se ofrece en la publicación o te devolvemos el dinero.
          </p>
          <div className="reputation-section">
            <img src="reputation2.jpg" alt="Reputación Mercado Libre 2" className="reputation-image" />
            <img src="reputation1.jpg" alt="Reputación Mercado Libre 1" className="reputation-image" />
            <img src="reputation3.jpg" alt="Reputación Mercado Libre 3" className="reputation-image" />
          </div>
          <button className='btn-about-us' onClick={() => window.open('https://mercadolibre.com.ar/perfil/ALFILDIGITAL', '_blank', 'noopener noreferrer')}>
              ¡Quiero ver!
          </button>
          <p className="reputation-text">¡Tenemos miles de ventas concretadas y más de mil reseñas positivas!</p>
        </section>
      );
    }

export default AboutUs;
