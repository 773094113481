import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/authAdmin.js';
import '../styles/loginModal.css'; // Asegúrate de tener los estilos para el modal
import { REACT_APP_HOST_HOOKS_DEV } from '../config/config.js';

const LoginModal = ({ isOpen, onClose }) => {
  const [loginData, setLoginData] = useState({
    username: '',
    password: ''
  });
  const { adminLogin } = useContext(AuthContext);

  const login = (loginData) => {
    fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(loginData),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error al iniciar sesión');
        }

        return response.json();
      })
      .then(data => {
        const username = data.payload.username;
        console.log(username, 'Inicio de sesión exitoso');
        adminLogin();
        onClose(); // Cerrar el modal después de iniciar sesión
      })
      .catch(error => {
        console.error('Error:', error);
      });

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login(loginData);
  };

  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Username:</label>
            <input type="text" name="username" value={loginData.username} onChange={handleChange} required />
          </div>
          <div>
            <label>Password:</label>
            <input type="password" name="password" value={loginData.password} onChange={handleChange} required />
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default LoginModal;