import React, { useState } from 'react';
import '../../styles/checkout/checkout.css';

const ImageModalComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <div className='container-payment-p'>
      <p className="payment-logo">
        Medios de pago disponibles con Mercado Pago. <em className='em-payment'><p onClick={() => openModal('brand.png')}>Saber más</p></em></p>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Selected" className="modal-image" onClick={closeModal}/>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageModalComponent;