import React from 'react';
import '../../styles/admin/transactions.css';

const TransactionList = ({ transactions }) => {
  const getPaymentMethod = (status) => {
    if (status === 'accredited') return 'MercadoPago';
    if (status === 'COMPLETED') return 'PayPal';
    return 'Desconocido';
  };

  const getStatusLabel = (status) => {
    if (status === 'accredited' || status === 'COMPLETED') return 'Completado';
    if (status === 'pending') return 'Pendiente';
    return 'Desconocido';
  };

  return (
    <div className="transaction-list">
      <h2>Lista de Transacciones</h2>
      {transactions.slice().reverse().map((transaction) => (
        <div key={transaction.id} className="transaction-item">
          <p>ID= {transaction.id}</p>
          <p>Estado= {getStatusLabel(transaction.status)}</p>
          <p>Email= {transaction.email}</p>
          <p>Método de pago: {getPaymentMethod(transaction.status)}</p>
        </div>
      ))}
    </div>
  );
};

export default TransactionList;